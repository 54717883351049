import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`*`}{`*`}{`Due to weather conditions we will close at 5:30 today.  So no
5:30 class or open gym.  We’ll reopen at 9:00am on Saturday.  If you
planned on attending the 5:30 class today to do the Pre Open WOD 4 email
or text Daniel and we’ll try to get you in tomorrow.`}</strong></p>
    <p><strong parentName="p">{`Pre Open WOD 4`}</strong></p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`9-S2OH (135/95) (Scaled 115/75) (Masters 95/65)`}</p>
    <p>{`15-GHD Situps (Scaled & Masters=regular situps)`}</p>
    <p>{`21-Wall Balls (RX & Scaled=20/14`}{`#`}{`, 10/9′)(Masters=14/10`}{`#`}{`, 10/9′)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our Free class at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, January 13th is Cardinal Fitness Day at the Yum!.  The
Cards play Virginia Tech at 4:00pm.  With the following link you can get
discounted tickets as a CrossFit the Ville member!  After the game there
will be a CrossFit wod on the court along with several other workout
sessions.  Lets get a big group together and represent The Ville at the
Yum center!  Family is invited as well.`}</em></strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      